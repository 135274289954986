<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.6"
    spinner-medium
    spinner-variant="primary"
    spinner-type="grow"
  >
    <div
      id="point-details"
    >
      <!-- Alert: No point found -->
      <b-alert
        variant="danger"
        :show="point === undefined"
      >
        <h4 class="alert-heading">
          {{ $t('errors.data.error_fetching_request_data') }}
        </h4>
        <div class="alert-body">
          {{ $t('errors.data.no_request_found_with_this_id') }}.
        </div>
      </b-alert>
      <div v-if="point && point.id">
        <request-header
          :proposal-can-be-added="proposalCanBeAdded"
          :proposal-added="proposalAdded"
        />
        <b-row>
          <b-col
            lg="8"
          >
            <div class="tabs">
              <b-nav tabs>
                <b-nav-item
                  to="#"
                  :active="$route.hash === '#' || $route.hash === ''"
                >
                  <feather-icon
                    class="d-none d-sm-block"
                    icon="FileTextIcon"
                  />
                  <span>{{ $t('Details') }}</span>
                </b-nav-item>
                <b-nav-item
                  to="#proposals"
                  :active="$route.hash === '#proposals'"
                >
                  <feather-icon
                    class="d-none d-sm-block"
                    icon="ThumbsUpIcon"
                  />
                  <b-spinner
                    v-if="totalProposals===null"
                    type="grow"
                    small
                    label="Loading..."
                  />
                  <span v-if="totalProposals!==null"> {{ $tc('Proposals:', totalProposals) }}</span>
                </b-nav-item>
              </b-nav>
              <div class="tab-content">
                <div
                  :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]"
                >
                  <b-card-text>
                    <request-details />
                  </b-card-text>
                </div>
                <div
                  :class="['tab-pane', { 'active': $route.hash === '#proposals' }]"
                >
                  <b-card-text>
                    <proposal-list
                      :point="point"
                      @total-proposals="(n) => updateTotalProposals(n)"
                      @proposal-can-be-added="(val) =>updateProposalCanBeAdded(val)"
                    />
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            lg="4"
          >
            <b-tabs>
              <b-tab
                v-if="ability.can('update', point) && point.receiver"
                active
              >
                <template #title>
                  <feather-icon
                    icon="UserCheckIcon"
                    class="d-none d-sm-block"
                  />
                  <span>{{ $t('Contractor') }}</span>
                </template>

                <b-card>
                  <div class="position-absolute statuses">
                    <b-badge
                      variant="light-warning"
                    >
                      <small class="text-nowrap">
                        <feather-icon
                          icon="BookmarkIcon"
                        />
                      </small>
                    </b-badge>

                  </div>
                  <request-author-profile :user="point.receiver" />
                </b-card>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="UserIcon"
                    class="d-none d-sm-block"
                  />
                  <span>{{ $t('Customer') }}</span>
                </template>

                <b-card>
                  <div class="position-absolute statuses">
                    <b-badge
                      v-if="point.managed"
                      variant="light-danger"
                    >
                      <small>
                        {{ $t('point.hidden_to_contractors') }}
                        <feather-icon
                          icon="EyeOffIcon"
                        />
                      </small>
                    </b-badge>
                  </div>
                  <request-author-profile :user="point.user" />
                </b-card>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>

      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAlert, BCardText, BSpinner, BNav, BNavItem, BRow, BCol, BTab, BTabs, BCard, BBadge, BOverlay,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { isMobile, formatTimeOfStay, getFormattedAddress } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { showToast } from '@/mixins/notification/toasts'
import RequestAuthorProfile from '@/views/pages/account-setting/AccountSettingUserProfile.vue'
import RequestHeader from './details/RequestHeader.vue'
import RequestDetails from './details/RequestDetails.vue'
import ProposalList from '../proposal/list/ProposalList.vue'

export default {
  components: {
    BAlert,
    BCardText,
    BSpinner,
    BNav,
    BNavItem,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BBadge,
    BOverlay,
    RequestHeader,
    RequestDetails,
    ProposalList,
    RequestAuthorProfile,
  },
  mixins: [isMobile, formatTimeOfStay, getFormattedAddress, showToast],
  data() {
    return {
      totalProposals: null,
      proposalCanBeAdded: false,
      proposalAdded: false,
    }
  },

  methods: {
    updateTotalProposals(n) {
      this.totalProposals = n
    },
    updateProposalCanBeAdded(val) {
      this.proposalCanBeAdded = val
      this.proposalAdded = !val
    },
  },
  setup() {
    const loading = ref(false)
    const {
      handleCartActionClick,
      togglePointInWishlist,
      resolvePointTypeBadgeVariant,
    } = usePointsUi()

    const point = ref(null)
    const mediaFiles = ref([])
    const ability = defineAbilityForCurrentUser()
    // Get point  id from URL
    const { route } = useRouter()
    const pointId = route.value.params.id

    // Remote Data
    const fetchPoint = () => {
      loading.value = true
      store.dispatch('point/fetchServiceRequest', { id: pointId })
        .then(response => {
          point.value = store.getters['point/Point']
          if (response.data.data.main_image !== null && typeof response.data.data.main_image) {
            mediaFiles.value.push(response.data.data.main_image.url)
          }
          if (typeof response.data.data.media_files !== 'undefined' && response.data.data.media_files.length > 0) {
            response.data.data.media_files.forEach(file => {
              mediaFiles.value.push(file.url)
            })
          }
          point.value.mediaFiles = mediaFiles.value
          store.commit('point/setPoint', point.value)
        })
        .catch(error => {
          point.value = undefined
        })
        .finally(() => { loading.value = false })
    }

    fetchPoint()
    // UI
    const selectedColor = ref(null)

    return {
      // Fetched Point
      point,
      ability,
      mediaFiles,
      loading,

      // UI
      selectedColor,
      handleCartActionClick,
      resolvePointTypeBadgeVariant,
      togglePointInWishlist,
    }
  },
}

</script>
<style scoped>

.statuses{
  top: 0;
  right:0;
  margin-right: 0!important;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}

</style>
